<template>
    <Modal label="Data Pasaran">
        <InputCustom
            v-model="item.kode_pasaran"
            :error="error.kode_pasaran"
            :errorMsg="errorMsg.kode_pasaran"
            @onFocus="resetError('kode_pasaran')"
            placeholder="Kode Pasaran"
            label="Kode Pasaran"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.keterangan"
            :error="error.keterangan"
            :errorMsg="errorMsg.keterangan"
            @onFocus="resetError('keterangan')"
            placeholder="Keterangan"
            label="Keterangan Pasaran"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.jam_buka"
            :error="error.jam_buka"
            :errorMsg="errorMsg.jam_buka"
            @onFocus="resetError('jam_buka')"
            placeholder="Jam Buka"
            label="Jam Buka Pasaran"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.jam_tutup"
            :error="error.jam_tutup"
            :errorMsg="errorMsg.jam_tutup"
            @onFocus="resetError('jam_tutup')"
            placeholder="Jam Tutup"
            label="Jam Tutup Pasaran"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.url"
            :error="error.url"
            :errorMsg="errorMsg.url"
            @onFocus="resetError('url')"
            placeholder="URL"
            label="URL Pasaran"
        >
        
        </InputCustom>

        <div class="y-inputField">
            <div class="y-labelText">
                Hari
            </div>
            <div class="r-grid-items-3">
                <div v-for="item in arrayOfDays" :key="item.id" class="r-center-flex r-grid-item" style="margin-right: 15px">
                    <input type="checkbox"
                        :value="item"
                        v-model="selectedDays"
                        style="width: 24px;height: 24px;"
                    >{{item}}
                </div>
            </div>
            <div class="y-errorText y-red-text" :style="error.inputType ? 'opacity: 1 !important;': ''">
                {{errorMsg.inputType}}
            </div>
        </div>
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
    },
    mounted() {
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'pasaran/getItem',
        })
    },
    data: () => ({
        error: {
            kode_pasaran: null,
            keterangan: null,
            jam_buka: null,
            jam_tutup: null,
            url: null,
        },
        errorMsg: {
            kode_pasaran: 'Tidak boleh kosong',
            keterangan: 'Tidak boleh kosong',
            jam_buka: 'Tidak boleh kosong',
            jam_tutup: 'Tidak boleh kosong',
            url: 'Tidak boleh kosong',
        },
        loading: false,
        arrayOfDays: ['Senin','Selasa','Rabu','Kamis','Jumat','Sabtu','Minggu'],
        selectedDays: []
    }),
    methods: {
        ...mapActions({
            store: 'pasaran/store',
            update: 'pasaran/update',
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['kode_pasaran', 'keterangan', 'jam_buka', 'jam_tutup']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            if (this.item['kode_pasaran'].length > 2) {
                this.error['kode_pasaran'] = true
                this.errorMsg['kode_pasaran'] = "Kode Pasaran Tidak Boleh Lebih dari 2 Karakter"
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                this.item.hari = this.selectedDays
                if (this.inputType == 'Tambah') {
                    await this.store(this.item)
                } else {
                    await this.update({payload: this.item, id: this.item.id})
                }
                this.loading = false
                const response = this.getResponse('pasaran')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>